<template>
  <div class="nuxt-error">
    <component :is="pageName" :error="error" />
  </div>
</template>
<script>
import error404 from '~/components/ErrorPages/404.vue';
import error500 from '~/components/ErrorPages/500.vue';

export default {
  name: 'NuxtError',

  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    pageName() {
      if (this.error?.statusCode === 404) {
        return error404;
      }
      return error500;
    }
  }
};
</script>
