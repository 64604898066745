import axios from 'axios';

export default (token) => {
  return axios
    .get(`https://www.google.com/recaptcha/api/siteverify`, {
      params: {
        secret: process.env.RECAPTCHA_SECRET_KEY,
        response: token
      }
    })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};
