import countries from '@/misc/countries';

export const state = () => ({
  config: {
    defaults: {}
  }
});

const regions = [
  {
    isoCurrency: 'USD',
    isoCountry: 'US'
  },
  {
    isoCurrency: 'GBP',
    isoCountry: 'GB'
  },
  {
    isoCurrency: 'CAD',
    isoCountry: 'CA'
  },
  {
    isoCurrency: 'EUR',
    isoCountry: 'EU'
  },
  {
    isoCurrency: 'USD',
    isoCountry: 'LATAM'
  },
  {
    isoCurrency: 'USD',
    isoCountry: 'GO'
  }
];

export const actions = {
  getConfig({ commit, state, dispatch }, { country = 'GO', locale = 'en-US' }) {
    const storeRegion = state?.config?.storeRegion;
    commit('SET_CONFIG', {
      defaultRegion: country,
      regions,
      locale
    });
    const isBannerEnabled = process.env.IS_BANNER_ENABLED;

    dispatch('setStoreRegion', storeRegion || country);
    dispatch('setIsBannerEnabled', isBannerEnabled);
  },
  setIsBannerEnabled({ state, commit }, isBannerEnabled = false) {
    commit('SET_IS_BANNER_ENABLED', isBannerEnabled);
  },
  setStoreRegion({ state, commit }, storeRegion = 'GO') {
    const availableRegions = state?.config?.regions || [];

    // Try to find the specified region in the available list
    let currentRegion = availableRegions.find(
      ({ isoCountry }) => isoCountry === storeRegion
    );

    if (!currentRegion) {
      // Use the GO region otherwise
      currentRegion = availableRegions.find(
        ({ isoCountry }) => isoCountry === 'GO'
      );
    }

    commit('SET_REGION_CONFIG', currentRegion);
  }
};

export const mutations = {
  SET_CONFIG(state, config) {
    state.config = config;
  },
  SET_REGION_CONFIG(state, region) {
    state.config.region = region.isoCountry;
    state.config.storeRegion = region.isoCountry;
    state.config.currency = region.isoCurrency;
    state.config.regionName = countries[region.isoCountry];
  },
  SET_CONFIG_CURRENCY(state, currency) {
    state.config.currency = currency;
  },
  SET_IS_BANNER_ENABLED(state, isBannerEnabled) {
    state.config.isBannerEnabled = isBannerEnabled;
  }
};

export const getters = {
  regions: (state) => state?.config?.regions || [],
  region: (state) => state?.config?.region,
  regionName: (state) => state?.config?.regionName,
  currency: (state) => state?.config?.currency,
  config: (state) => state?.config,
  shippingMethodId: (state) => process.env.DEFAULT_SHIPPING_METHOD_ID,
  isBannerEnabled: (state) => state.config.isBannerEnabled
};
