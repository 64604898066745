<template>
  <div id="mobile-menu" class="menu">
    <div class="d-flex justify-content-between">
      <img
        src="@/assets/logo.svg"
        alt="logo"
        @click="handleGoToDiscoveryPage"
      />
      <img
        class="ml-auto cursor-pointer"
        src="@/assets/icons/close.svg"
        alt="menu"
        @click="handleCloseMenuClick"
      />
    </div>

    <div class="menu__items">
      <h1
        v-for="item in menuItems"
        :key="item.title"
        :class="{ 'menu__dynamic-item': item.dynamic }"
        class="mb-3 cursor-pointer"
        @click="handleNavigateClick(item.path, item.dynamic, item.title)"
      >
        {{ item.title }}
        <img
          v-if="item.dynamic"
          class="menu__dynamic-item-arrow"
          :class="{
            'menu__dynamic-item-arrow-active': activeMenuItems[item.title]
          }"
          src="@/assets/icons/arrows/down-menu-mobile.svg"
          alt="arrow"
        />
        <div v-if="activeMenuItems[item.title]" class="menu-dropdown">
          <h2
            v-for="category in item.categories"
            :key="category.id"
            @click="handleCategoryClick(category)"
          >
            {{ category.name }}
          </h2>
        </div>
      </h1>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { disableBodyScroll } from 'body-scroll-lock';

export default {
  name: 'MenuComponent',

  data() {
    return {
      activeMenuItems: {}
    };
  },

  computed: {
    ...mapState('main', ['menuItems'])
  },

  mounted() {
    const menuElement = document.getElementById('mobile-menu');
    disableBodyScroll(menuElement);
    this.menuItems.forEach((item) => {
      if (item.dynamic) {
        this.activeMenuItems = Object.assign({}, this.activeMenuItems, {
          [item.title]: false
        });
      }
    });
  },

  methods: {
    ...mapMutations({
      SET_MENU_VISIBLE: 'main/SET_MENU_VISIBLE'
    }),
    handleCloseMenuClick() {
      this.SET_MENU_VISIBLE(false);
    },
    handleNavigateClick(path, isDynamicSection, title) {
      if (isDynamicSection) {
        if (this.activeMenuItems[title]) {
          this.activeMenuItems[title] = !this.activeMenuItems[title];
        } else {
          this.activeMenuItems[title] = true;
        }
      } else {
        this.$router.push(path);
        this.handleCloseMenuClick();
      }
    },
    handleGoToDiscoveryPage() {
      this.$router.push('/');
      this.handleCloseMenuClick();
    },
    handleCategoryClick(category) {
      this.$router.push(category.url);
      this.handleCloseMenuClick();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/components';

.main.hide-topbar .menu {
  top: 0;
}

.menu {
  position: fixed;
  left: 0;
  right: 0;
  top: $top-bar-height;
  bottom: 0;
  z-index: 15;
  height: 100%;
  background: $dark-color;
  padding: 15px;
  overflow: scroll;

  &__items {
    padding-top: 100px;

    h1 {
      color: $white-color;
      font-family: 'PPRader-Bold', sans-serif;
    }

    .menu__dynamic-item-arrow {
      transition: all 0.4s ease;
    }

    .menu__dynamic-item-arrow-active {
      transform: rotateZ(-180deg);
    }
  }

  &-dropdown {
    padding-left: 20px;

    h2 {
      line-height: 50px;
    }
  }
}
</style>
