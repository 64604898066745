import { event } from 'vue-gtag';
import Promise from 'bluebird';
import { axiosInstance } from '@/lib/axiosInstance';
import { fetchOneProduct } from '@/services/products';
import getFormatPrice from '~/misc/getFormatPrice';
import { getStore } from '@/lib/analytics/utils';

export const trackSaleEvent = (action) =>
  event(action, {
    event_category: 'Successful Sale',
    event: 'cart',
    event_label: 'Thank you page'
  });

export const trackPurchaseEvent = (action) =>
  event(action, {
    event_category: 'Purchase Click',
    event: 'cart',
    event_label: 'Purchase'
  });

/**
 * Produces an array of objects structured according to the specifications
 * required by Google Analytics 4 eCommerce events.
 *
 * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/ecommerce}
 * @param cartItems
 * @returns {Promise<{total: number, items: *[]}>}
 */
const generateGAItemsArray = ({ cartItems }) => {
  const region = getStore().getters['config/region'];
  const discount = getStore().getters['basket/discount'] ?? {
    percentOff: 0
  };

  return Promise.reduce(
    cartItems,
    async (acc, cartItem) => {
      const formats = cartItem.formats;
      const formatId = cartItem.productFormatId;
      const selectedProductFormat = formats.find(({ _id }) => _id === formatId);
      const price = getFormatPrice(selectedProductFormat, region);
      const priceAmount = +(price.amount / 100).toFixed(2);

      const product = cartItem.creator
        ? cartItem
        : await fetchOneProduct(axiosInstance, cartItem._id, {
            query: { $populate: { path: 'creator' } }
          });

      const item = {
        item_id: cartItem._id,
        item_name: cartItem.name,
        coupon: discount.code,
        discount: discount.percentOff
          ? +(priceAmount * (discount.percentOff / 100)).toFixed(2)
          : null,
        item_brand: product.creator.username,
        item_variant: selectedProductFormat.name,
        price: priceAmount,
        quantity: cartItem.quantity
      };
      acc.items.push(item);
      acc.total += (item.price - item.discount) * item.quantity;

      return acc;
    },
    { items: [], total: 0 }
  );
};

export const trackAddToCartEvent = async ({ cartItem }) => {
  const { items, total } = await generateGAItemsArray({
    cartItems: [cartItem]
  });

  const eventParams = {
    currency: getStore().getters['config/currency'],
    value: total,
    items
  };

  event('add_to_cart', eventParams);
};

export const trackRemoveFromCartEvent = async ({ cartItem }) => {
  const { items, total } = await generateGAItemsArray({
    cartItems: [cartItem]
  });

  const eventParams = {
    currency: getStore().getters['config/currency'],
    value: total,
    items
  };

  event('remove_from_cart', eventParams);
};

export const trackViewBasketEvent = async ({ cart }) => {
  const { items, total } = await generateGAItemsArray({
    cartItems: cart
  });

  const eventParams = {
    currency: getStore().getters['config/currency'],
    value: total,
    items
  };

  event('view_cart', eventParams);
};

export const trackCheckoutEvent = async ({ cart }) => {
  const { items, total } = await generateGAItemsArray({
    cartItems: cart
  });

  const eventParams = {
    currency: getStore().getters['config/currency'],
    value: total,
    items
  };

  event('begin_checkout', eventParams);
};

export const trackPurchaseGAEvent = async ({ order, cart }) => {
  const { items, total } = await generateGAItemsArray({
    cartItems: cart
  });

  const eventParams = {
    transaction_id: order?._id,
    currency: getStore().getters['config/currency'],
    value: total,
    tax: order?.totalTaxAmount,
    items
  };

  event('purchase', eventParams);
};
