import Cookies from 'js-cookie';
import createPersistedState from 'vuex-persistedstate';

/**
 * The current plugin creates a persisted Vuex state that is synchronized
 * between the server and client-side through the Cookies.
 */
export default ({ store, req, isDev }) => {
  const serverCookies = {};

  if (process.server) {
    // parse cookie header from "key1=value1; key2=value2" format
    const cookieHeader = req?.headers?.cookie || '';
    cookieHeader.split('; ').forEach((cookiePair) => {
      const [key, value] = cookiePair?.split('=');
      serverCookies[key] = decodeURIComponent(value);
    });
  }

  // Server retrieves the values from the "cookie" request header
  const ServerCookieStorage = {
    getItem(key) {
      if (!serverCookies[key]) return;
      try {
        const jsonValue = JSON.parse(serverCookies[key]);
        return jsonValue;
      } catch {}
    },
    setItem(key, value) {
      // ignore
    },
    removeItem(key) {
      // ignore
    }
  };

  // Client works with the "document.cookie" object
  const ClientCookieStorage = {
    getItem(key) {
      const value = Cookies.get(key);
      try {
        const jsonValue = JSON.parse(value);
        return jsonValue;
      } catch {}
    },
    setItem(key, value) {
      Cookies.set(key, value, { expires: 365, secure: !isDev });
    },
    removeItem(key) {
      Cookies.remove(key);
    }
  };

  const storage = process.server ? ServerCookieStorage : ClientCookieStorage;

  createPersistedState({
    key: 'vuex',
    paths: ['config.config.storeRegion', 'config.config.region'],
    storage
  })(store);
};
