import * as Sentry from '@sentry/browser';
import * as gaEvents from './ga';
import * as klaviyoEvents from './klaviyo';

export function trackSaleEvent(action) {
  safeExecute(() => gaEvents.trackSaleEvent(action));
}

export function trackAddToCartEvent({ cartItem, cart }) {
  safeExecute(
    () => gaEvents.trackAddToCartEvent({ cartItem }),
    () => klaviyoEvents.trackAddToCartEvent({ cartItem, cart })
  );
}

export function trackRemoveFromCartEvent({ cart, cartItem }) {
  safeExecute(
    () => gaEvents.trackRemoveFromCartEvent({ cartItem }),
    () => klaviyoEvents.trackRemoveFromCartEvent({ cart, cartItem })
  );
}

export function trackViewBasketEvent({ cart }) {
  safeExecute(() => gaEvents.trackViewBasketEvent({ cart }));
}

export function trackCheckoutEvent({ cart, redirectUrl, checkoutSessionId }) {
  safeExecute(
    () => gaEvents.trackCheckoutEvent({ cart }),
    () =>
      klaviyoEvents.trackCheckoutEvent({ cart, redirectUrl, checkoutSessionId })
  );
}

export function trackPurchaseEvent({ order, cart }) {
  safeExecute(
    () => gaEvents.trackPurchaseGAEvent({ order, cart }),
    () => klaviyoEvents.identify({ order })
  );
}

export function trackProductViewedEvent({ product }) {
  safeExecute(() => klaviyoEvents.trackProductViewedEvent({ product }));
}

/**
 * Safely runs an array of track events functions,
 * catching any errors they may throw and handling them by reporting to Sentry.
 *
 * @param {...Function} fns
 * @returns {Promise<void>}
 */
function safeExecute(...fns) {
  return fns.forEach(async (fn) => {
    try {
      await fn();
    } catch (err) {
      // sentry report
      Sentry.captureMessage(
        'Error while executing the logic to track the event',
        {
          extra: { err }
        }
      );
      console.error(err);
    }
  });
}
