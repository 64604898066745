<template>
  <div class="container text-center">
    <div class="error-page">
      <h2 class="mb-3">Sorry we can not find the page you are looking for.</h2>
      <p class="mb-4">Try searching by creator name, book title or recipe</p>
      <b-form class="d-flex" @submit.prevent="handleSearch">
        <b-row>
          <b-col sm="12" md="10">
            <b-input-group class="search__input">
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="custom-form-input"
              />
            </b-input-group>
          </b-col>
          <b-col md="2" sm="12" class="mt-md-0 mt-4">
            <Button @click="handleSearch">Search</Button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Elements/Button';

export default {
  name: 'ErrorPage404',

  components: {
    Button
  },

  data() {
    return {
      searchTerm: null
    };
  },

  methods: {
    handleSearch() {
      if (this.searchTerm) {
        this.$router.push({
          name: 'search',
          query: { keyword: this.searchTerm }
        });
      } else {
        this.$router.push({ name: 'search' });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$header-height: 55px;
$footer-height: 220px;

.error-page {
  height: calc(100vh - ($header-height + $footer-height));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('assets/404.svg');
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;

  &__primary-link {
    color: $primary-color;
  }

  .custom-form-input {
    width: 400px;

    @media (max-width: $screen-width-md) {
      width: 100%;
    }
  }
}
</style>
