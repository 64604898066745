<template>
  <div>
    <b-dropdown
      class="RegionDropdown"
      toggle-class="RegionDropdown-Toggle d-flex align-items-center text-white px-0"
      variant="transparent"
      right
      no-caret
      @show="onShow"
      @hide="onHide"
    >
      <template #button-content>
        <img class="RegionFlag" :src="active.icon" :alt="active.isoCountry" />
        <span class="mx-2">{{ active.isoCurrency }}</span>
        <SwitchIcon :is-active="isOpened" size="8" />
      </template>

      <b-dropdown-item
        v-for="option in regionOptions"
        :key="option.isoCountry"
        class="RegionDropdown-Item"
        link-class="px-2 py-2"
        @click="onChange(option)"
      >
        <div class="d-flex align-items-center">
          <img
            class="RegionFlag mr-2"
            :src="option.icon"
            :alt="option.isoCountry"
          />
          {{ option.country }} - {{ option.currencySymbol
          }}{{ option.isoCurrency }}
        </div>
      </b-dropdown-item>
    </b-dropdown>

    <ConfirmDialog
      v-model="isConfirmShown"
      :region="intentRegion"
      @ok="onChangeConfirm"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import { countryIcons } from './constants';
import ConfirmDialog from './ConfirmDialog.vue';
import { countryNames } from '@/misc/constants';
import { getCurrencySymbol } from '@/misc/formatMoney';
import SwitchIcon from '@/components/Elements/SwitchIcon';
import reloadPageWithoutCache from '@/misc/reloadPageWithoutCache';

export default {
  name: 'RegionDropdown',

  components: {
    SwitchIcon,
    ConfirmDialog
  },

  data() {
    return {
      isOpened: false,
      isConfirmShown: false,
      unavailableProducts: [],
      intentRegion: null
    };
  },

  computed: {
    ...mapGetters('config', ['regions', 'region']),
    ...mapState('checkout', ['products']),
    regionOptions() {
      return this.regions.map(({ isoCountry, isoCurrency }) => ({
        isoCurrency,
        currencySymbol: getCurrencySymbol(isoCurrency),
        isoCountry,
        icon: countryIcons[isoCountry],
        country: countryNames[isoCountry]
      }));
    },
    active() {
      return (
        this.regionOptions.find(
          ({ isoCountry }) => isoCountry === this.region
        ) || this.regionOptions[0]
      );
    }
  },

  methods: {
    ...mapActions('config', ['setStoreRegion']),
    ...mapMutations('checkout', ['REMOVE_ITEMS']),
    onShow() {
      this.isOpened = true;
    },
    onHide() {
      this.isOpened = false;
    },
    onChange(region) {
      if (!region || region.isoCountry === this.storeRegion) return;

      this.unavailableProducts = this.getUnavailableProducts(
        region?.isoCountry
      );

      if (this.unavailableProducts.length) {
        // show confirmation modal dialog
        this.isConfirmShown = true;
        this.intentRegion = region;
        return;
      }

      this.changeRegion(region.isoCountry);
    },
    getUnavailableProducts(region) {
      return this.products
        .map((product) => ({
          ...product,
          format: product.formats?.find(
            (format) => format._id === product.productFormatId
          )
        }))
        .filter((product) => {
          return !product.format?.prices?.find(
            (price) => price.region === region
          );
        });
    },
    onChangeConfirm() {
      const productIds = this.unavailableProducts.map(({ _id }) => _id);
      this.unavailableProducts = [];

      // Remove unavailable products from the cart before the region change
      this.REMOVE_ITEMS(productIds);

      this.changeRegion(this.intentRegion?.isoCountry);
      this.isConfirmShown = false;
    },
    changeRegion(isoCountry) {
      // Change region and reload the page to re-fetch the products
      this.setStoreRegion(isoCountry);
      reloadPageWithoutCache();
    }
  }
};
</script>

<style lang="scss" scoped>
.RegionDropdown {
  &-Item {
    a {
      img.RegionFlag {
        box-shadow: 0 0 0 0.5px rgba(123, 127, 142, 0.5);
      }
    }
  }

  img.RegionFlag {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid transparent;
    box-shadow: 0 0 0 0.5px #fff;
  }
}
</style>

<style lang="scss">
.RegionDropdown {
  &-Toggle:focus {
    box-shadow: none;
  }

  .dropdown-menu {
    padding: 0;
    border-radius: 8px;

    .dropdown-item {
      &:hover {
        border-radius: 8px;
        background-color: rgba(221, 202, 109, 0.15);
      }
    }
  }
}
</style>
