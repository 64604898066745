import { last, size } from 'lodash';

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

function hasPayoutCodeParams(route) {
  return !!route.query?.code;
}

const disabledRoutes = [
  '/blog/growyourbrandwithtiktok',
  '/blog/cookingupcash',
  '/blog/lockdowncooking'
];

export default ({ route, redirect, app }) => {
  if (disabledRoutes.includes(route.path)) {
    return redirect('/404');
  }

  app.router.beforeEach((to, from, next) => {
    if (app.store.getters['main/isInstagramBrowser'] && from.name !== null) {
      if (process.browser) {
        if (size(window.location.search) && size(to.query) === 0) {
          window.location.href =
            window.location.origin + to.path + window.location.search;
        } else {
          window.location.href = window.location.origin + to.fullPath;
        }
      }
    } else if (!hasQueryParams(to) && hasPayoutCodeParams(from)) {
      // Take the last code
      const query = from.query;
      const code = Array.isArray(query.code) ? last(query.code) : query.code;
      next({ path: to.path, query: { ...query, code } });
    } else {
      next();
    }
  });
};
