import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
