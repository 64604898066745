<template>
  <div
    class="payment-icons"
    :class="{
      'payment-icons--product': variant === 'productPage',
      'payment-icons--footer': variant === 'footer',
      'payment-icons--basket': variant === 'basket',
      'd-flex': variant === 'footer',
      'justify-content-center': variant === 'footer'
    }"
  >
    <div>
      <div class="payment-icons__list">
        <div v-if="variant !== 'footer'" class="payment-icons__icon">
          <img src="./generic-card.svg" />
        </div>
        <div v-if="variant === 'footer'" class="payment-icons__icon">
          <img src="./visa.svg" />
        </div>
        <div v-if="variant === 'footer'" class="payment-icons__icon">
          <img src="./mastercard.svg" />
        </div>
        <div v-if="variant === 'footer'" class="payment-icons__icon">
          <img src="./amex.svg" />
        </div>
        <div class="payment-icons__icon">
          <img src="./link.svg" />
        </div>
        <div class="payment-icons__icon">
          <img src="./google-pay.svg" />
        </div>
        <div class="payment-icons__icon">
          <img src="./apple-pay.svg" />
        </div>
        <div class="payment-icons__icon">
          <img src="./clearpay.svg" />
        </div>
        <div class="payment-icons__icon">
          <img src="./klarna.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'productPage',
      enum: ['productPage', 'basket', 'footer']
    }
  }
};
</script>

<style lang="scss" scoped>
.payment-icons {
  margin: 0 auto;
  @media (min-width: $screen-width-lg) {
    margin: 0;
  }

  &__powered-stripe {
    font-size: 10px;
    text-align: center;
    margin-bottom: 20px;

    @media (min-width: $screen-width-md) {
      text-align: right;
    }

    @media (max-width: $screen-width-lg) {
      text-align: center;
    }

    b {
      font-weight: bold;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 12.7px;
    margin: 0 -3px;
  }

  &__icon {
    padding: 0 6px;
    margin-bottom: 12px;
    display: flex;
    img {
      width: 45px;
      @media (min-width: $screen-width-lg) {
        width: 39px;
      }
    }
  }

  &.payment-icons--product,
  &.payment-icons--basket,
  &.payment-icons--footer {
    .payment-icons__list {
      @media (min-width: $screen-width-lg) {
        max-width: 310px;
      }
    }
  }

  &.payment-icons--basket {
    .payment-icons__icon {
      img {
        width: 38px;
      }
    }
  }
}
</style>
