import get from 'lodash/get';
import split from 'lodash/split';
import compact from 'lodash/compact';
import getRegionByCountry from '@/misc/getRegionByCountry';

export const actions = {
  async nuxtServerInit({ dispatch, commit }, { req }) {
    commit(
      'main/SET_NAVIGATOR_PLATFORM',
      get(req, 'headers.sec-ch-ua-platform', null)
    );

    const {
      'x-vercel-ip-country': country,
      'accept-language': acceptLanguage
    } = req.headers;

    const locale = compact(split(acceptLanguage, ','))[0];

    await Promise.all([
      dispatch('config/getConfig', {
        country: getRegionByCountry(country),
        locale
      }),
      dispatch('main/getMenuSections')
    ]);
  }
};
