<template>
  <b-overlay :show="loading" variant="transparent" rounded="sm" v-bind="$attrs">
    <b-button
      v-if="type"
      :id="id"
      :type="type"
      :class="btnClass"
      :variant="variant"
      class="btn"
    >
      <slot></slot>
    </b-button>
    <a
      v-else
      :id="id"
      :class="btnClass"
      class="btn"
      @click="!disabled && $emit('click', $event)"
    >
      <slot></slot>
    </a>
  </b-overlay>
</template>

<script>
export default {
  name: 'Button',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    pill: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (value) =>
        ['primary', 'secondary', 'outline', 'outline-inverse'].includes(value)
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['sm', 'md'].includes(value)
    },
    type: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  computed: {
    btnClass() {
      return {
        'btn-disabled': this.disabled,
        'btn-fullwidth': this.fullWidth,
        'rounded-pill': this.pill,
        'btn-md': this.size === 'md',
        'btn-sm': this.size === 'sm px-2',
        'btn-primary': this.variant === 'primary',
        'btn-secondary': this.variant === 'secondary',
        'btn-outline': this.variant === 'outline',
        'btn-outline-inverse': this.variant === 'outline-inverse'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/utilites';
@import '../../styles/components';

.btn {
  display: inline-block;
  text-transform: $button-text-transform;
  text-align: center;
  box-shadow: none;
  outline: 0;
  margin: 0;
  transition: background 0.3s ease, color 0.3s ease, border 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  font-size: $font-size-semi-large;

  &:focus {
    text-decoration: none;
  }

  &.btn-disabled {
    cursor: not-allowed !important;
    text-decoration: none;
  }

  &.btn-fullwidth {
    width: 100%;
  }

  &.rounded-pill {
    border-radius: 50rem !important;
  }

  /* Sizing */

  &.btn-sm {
    padding: 5px 15px;
    border-radius: $button-border-radius-sm;
  }

  &.btn-md {
    padding: 15px 30px;
    border-radius: $button-border-radius-md;
  }

  /* theme applying */
  $button-condition-to-selector: (
    default: '',
    focus: ':focus',
    hover: ':hover',
    active: ':active',
    disabled: '.btn-disabled' '.btn-disabled:active' '.btn-disabled:focus',
    invalid: '.is-invalid'
  );

  @each $type, $props in $buttons {
    @each $prop, $conditions in $props {
      @each $condition in map-keys($button-condition-to-selector) {
        @each $selector in _get($button-condition-to-selector, $condition) {
          &.btn-#{$type}#{$selector} {
            #{$prop}: _get($conditions, $condition) !important;
          }
        }
      }
    }
  }
}
</style>
