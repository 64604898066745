import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _80894ed6 = () => interopDefault(import('../pages/404/index.vue' /* webpackChunkName: "pages/404/index" */))
const _5aab9412 = () => interopDefault(import('../pages/500/index.vue' /* webpackChunkName: "pages/500/index" */))
const _77108177 = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _5b7113df = () => interopDefault(import('../pages/basket/index.vue' /* webpackChunkName: "pages/basket/index" */))
const _7e6c7591 = () => interopDefault(import('../pages/be-found/index.vue' /* webpackChunkName: "pages/be-found/index" */))
const _e8ad238a = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _4d16407d = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _b6873fa8 = () => interopDefault(import('../pages/cookies/index.vue' /* webpackChunkName: "pages/cookies/index" */))
const _c6a429c0 = () => interopDefault(import('../pages/creators/index.vue' /* webpackChunkName: "pages/creators/index" */))
const _8f63ca7c = () => interopDefault(import('../pages/discover/index.vue' /* webpackChunkName: "pages/discover/index" */))
const _1553c436 = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _3878167d = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _576fcd41 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _13c579a4 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _20f4285a = () => interopDefault(import('../pages/basket/success/index.vue' /* webpackChunkName: "pages/basket/success/index" */))
const _0cf00c7e = () => interopDefault(import('../pages/be-found/constants.js' /* webpackChunkName: "pages/be-found/constants" */))
const _6ef09892 = () => interopDefault(import('../pages/blog/bestsocialmediaplatforms/index.vue' /* webpackChunkName: "pages/blog/bestsocialmediaplatforms/index" */))
const _49cd2fd2 = () => interopDefault(import('../pages/blog/cookingcomedychaos/index.vue' /* webpackChunkName: "pages/blog/cookingcomedychaos/index" */))
const _0eadefde = () => interopDefault(import('../pages/blog/cookinginthemidwest/index.vue' /* webpackChunkName: "pages/blog/cookinginthemidwest/index" */))
const _878888d8 = () => interopDefault(import('../pages/blog/cookingupcash/index.vue' /* webpackChunkName: "pages/blog/cookingupcash/index" */))
const _2aa82661 = () => interopDefault(import('../pages/blog/creators.js' /* webpackChunkName: "pages/blog/creators" */))
const _61400eda = () => interopDefault(import('../pages/blog/divyateachesechildrenaboutcooking/index.vue' /* webpackChunkName: "pages/blog/divyateachesechildrenaboutcooking/index" */))
const _8c3162c8 = () => interopDefault(import('../pages/blog/eatwellwithgeorgia/index.vue' /* webpackChunkName: "pages/blog/eatwellwithgeorgia/index" */))
const _5db13317 = () => interopDefault(import('../pages/blog/fitfooddiary_x/index.vue' /* webpackChunkName: "pages/blog/fitfooddiary_x/index" */))
const _31eff1a6 = () => interopDefault(import('../pages/blog/growyourbrandwithtiktok/index.vue' /* webpackChunkName: "pages/blog/growyourbrandwithtiktok/index" */))
const _2689152f = () => interopDefault(import('../pages/blog/itsbabacooks/index.vue' /* webpackChunkName: "pages/blog/itsbabacooks/index" */))
const _d8acb94e = () => interopDefault(import('../pages/blog/lockdowncooking/index.vue' /* webpackChunkName: "pages/blog/lockdowncooking/index" */))
const _1a909f3f = () => interopDefault(import('../pages/blog/mississippikween/index.vue' /* webpackChunkName: "pages/blog/mississippikween/index" */))
const _c9bdb4ee = () => interopDefault(import('../pages/blog/socialmediachef/index.vue' /* webpackChunkName: "pages/blog/socialmediachef/index" */))
const _b7519622 = () => interopDefault(import('../pages/blog/yourbarefootneighbor/index.vue' /* webpackChunkName: "pages/blog/yourbarefootneighbor/index" */))
const _4be94dd6 = () => interopDefault(import('../pages/cookies/constant.js' /* webpackChunkName: "pages/cookies/constant" */))
const _6b2c8c8e = () => interopDefault(import('../pages/privacy/constant.js' /* webpackChunkName: "pages/privacy/constant" */))
const _a99816e6 = () => interopDefault(import('../pages/terms/constant.js' /* webpackChunkName: "pages/terms/constant" */))
const _334713ea = () => interopDefault(import('../pages/creators/_id/index.vue' /* webpackChunkName: "pages/creators/_id/index" */))
const _329110b2 = () => interopDefault(import('../pages/products/_id/index.js' /* webpackChunkName: "pages/products/_id/index" */))
const _04e4f5dc = () => interopDefault(import('../pages/recipe/_id/index.vue' /* webpackChunkName: "pages/recipe/_id/index" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8aad2832 = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _b4e7895c = () => interopDefault(import('../pages/_slug/_id/index.vue' /* webpackChunkName: "pages/_slug/_id/index" */))
const _5022c88e = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _80894ed6,
    name: "404"
  }, {
    path: "/500",
    component: _5aab9412,
    name: "500"
  }, {
    path: "/about-us",
    component: _77108177,
    name: "about-us"
  }, {
    path: "/basket",
    component: _5b7113df,
    name: "basket"
  }, {
    path: "/be-found",
    component: _7e6c7591,
    name: "be-found"
  }, {
    path: "/blog",
    component: _e8ad238a,
    name: "blog"
  }, {
    path: "/contact",
    component: _4d16407d,
    name: "contact"
  }, {
    path: "/cookies",
    component: _b6873fa8,
    name: "cookies"
  }, {
    path: "/creators",
    component: _c6a429c0,
    name: "creators"
  }, {
    path: "/discover",
    component: _8f63ca7c,
    name: "discover"
  }, {
    path: "/privacy",
    component: _1553c436,
    name: "privacy"
  }, {
    path: "/products",
    component: _3878167d,
    name: "products"
  }, {
    path: "/search",
    component: _576fcd41,
    name: "search"
  }, {
    path: "/terms",
    component: _13c579a4,
    name: "terms"
  }, {
    path: "/basket/success",
    component: _20f4285a,
    name: "basket-success"
  }, {
    path: "/be-found/constants",
    component: _0cf00c7e,
    name: "be-found-constants"
  }, {
    path: "/blog/bestsocialmediaplatforms",
    component: _6ef09892,
    name: "blog-bestsocialmediaplatforms"
  }, {
    path: "/blog/cookingcomedychaos",
    component: _49cd2fd2,
    name: "blog-cookingcomedychaos"
  }, {
    path: "/blog/cookinginthemidwest",
    component: _0eadefde,
    name: "blog-cookinginthemidwest"
  }, {
    path: "/blog/cookingupcash",
    component: _878888d8,
    name: "blog-cookingupcash"
  }, {
    path: "/blog/creators",
    component: _2aa82661,
    name: "blog-creators"
  }, {
    path: "/blog/divyateachesechildrenaboutcooking",
    component: _61400eda,
    name: "blog-divyateachesechildrenaboutcooking"
  }, {
    path: "/blog/eatwellwithgeorgia",
    component: _8c3162c8,
    name: "blog-eatwellwithgeorgia"
  }, {
    path: "/blog/fitfooddiary_x",
    component: _5db13317,
    name: "blog-fitfooddiary_x"
  }, {
    path: "/blog/growyourbrandwithtiktok",
    component: _31eff1a6,
    name: "blog-growyourbrandwithtiktok"
  }, {
    path: "/blog/itsbabacooks",
    component: _2689152f,
    name: "blog-itsbabacooks"
  }, {
    path: "/blog/lockdowncooking",
    component: _d8acb94e,
    name: "blog-lockdowncooking"
  }, {
    path: "/blog/mississippikween",
    component: _1a909f3f,
    name: "blog-mississippikween"
  }, {
    path: "/blog/socialmediachef",
    component: _c9bdb4ee,
    name: "blog-socialmediachef"
  }, {
    path: "/blog/yourbarefootneighbor",
    component: _b7519622,
    name: "blog-yourbarefootneighbor"
  }, {
    path: "/cookies/constant",
    component: _4be94dd6,
    name: "cookies-constant"
  }, {
    path: "/privacy/constant",
    component: _6b2c8c8e,
    name: "privacy-constant"
  }, {
    path: "/terms/constant",
    component: _a99816e6,
    name: "terms-constant"
  }, {
    path: "/creators/:id",
    component: _334713ea,
    name: "creators-id"
  }, {
    path: "/products/:id",
    component: _329110b2,
    name: "products-id"
  }, {
    path: "/recipe/:id",
    component: _04e4f5dc,
    name: "recipe-id"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index"
  }, {
    path: "/:slug",
    component: _8aad2832,
    name: "slug"
  }, {
    path: "/:slug/:id",
    component: _b4e7895c,
    name: "slug-id"
  }, {
    path: "/*",
    component: _5022c88e,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
