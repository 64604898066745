<template>
  <div class="main" :class="{ 'mac-os': isMacOS, 'hide-topbar': hideTopbar }">
    <Header />
    <Nuxt class="main-container" />
    <Footer />
    <Transition name="slide">
      <Menu v-if="menuVisible" />
    </Transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { debounce, last } from 'lodash';
import { bootstrap, set, setOptions } from 'vue-gtag';

import Footer from '@/components/Footer';
import Header from '@/components/Header';
import Menu from '@/components/Menu';

import { fetchPayoutCode } from '~/services/payoutCodes';

export default {
  name: 'MainLayout',

  components: {
    Header,
    Footer,
    Menu
  },

  head() {
    const script = [];
    if (process.env.KLAVIYO_PUBLIC_KEY) {
      script.push({
        async: true,
        type: 'text/javascript',
        src: `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.KLAVIYO_PUBLIC_KEY}`
      });
    }

    return { script };
  },

  computed: {
    ...mapState('main', [
      'menuVisible',
      'payoutCode',
      'windowHeight',
      'windowWidth',
      'questionInputFocused',
      'platform'
    ]),
    ...mapState('config', ['config']),
    ...mapGetters({
      isMacOS: 'main/isMacOS',
      isInstagramBrowser: 'main/isInstagramBrowser',
      isAndroidBrowser: 'main/isAndroidBrowser'
    }),
    hideTopbar() {
      return ['checkout', 'basket-success'].includes(this.$route.name);
    }
  },
  created() {
    /* eslint-disable */
    if (process.client) {
      window.addEventListener('resize', this.handleWindowResize);
      this.handleWindowResize();

      window.addEventListener('error', this.$sentry.captureException);
    }
    /* eslint-disable */
  },
  mounted() {
    this.SET_USER_AGENT(navigator?.userAgent);
    if (!this.platform) {
      this.SET_NAVIGATOR_PLATFORM(
        navigator?.userAgentData?.platform || navigator?.platform || null
      );
    }
    this.SET_MENU_VISIBLE(false);
    this.initGoogleAnalytics(process.env.GA_TRACKING_ID);

    // Take the last code
    const payoutCodeQuery = this.$route.query.code;

    if (payoutCodeQuery) {
      const payoutCode = Array.isArray(payoutCodeQuery)
        ? last(payoutCodeQuery)?.toLowerCase()
        : payoutCodeQuery?.toLowerCase();

      if (payoutCode) {
        fetchPayoutCode(this.$axios, payoutCode)
          .then((code) => {
            set({
              page_referrer: window.location.href
            });
            this.SET_PAYOUT_CODE({
              code: payoutCode,
              source: code.source
            });
          })
          .catch(() => {
            throw Error('Incorrect payout code: ' + payoutCode);
          });
      }
    } else {
      const localSource = localStorage.getItem('PAYOUT_SOURCE');

      if (localSource) {
        this.SET_SOURCE_CODE(localSource);
      }
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('error', this.$sentry.captureException);
  },
  methods: {
    ...mapMutations({
      SET_MENU_VISIBLE: 'main/SET_MENU_VISIBLE',
      SET_PAYOUT_CODE: 'main/SET_PAYOUT_CODE',
      SET_SOURCE_CODE: 'main/SET_SOURCE_CODE',
      SET_WINDOW_WIDTH: 'main/SET_WINDOW_WIDTH',
      SET_NAVIGATOR_PLATFORM: 'main/SET_NAVIGATOR_PLATFORM',
      SET_WINDOWS_HEIGHT: 'main/SET_WINDOWS_HEIGHT',
      SET_MOBILE_KEYBOARD_VISIBLE: 'main/SET_MOBILE_KEYBOARD_VISIBLE',
      SET_USER_AGENT: 'main/SET_USER_AGENT'
    }),
    initGoogleAnalytics(trackingId) {
      if (!trackingId) return;

      setOptions({ config: { id: trackingId } });
      return bootstrap();
    },
    handleWindowResize() {
      if (this.isAndroidBrowser) {
        this.handleMobileKeyboardVisible();
        this.SET_WINDOW_WIDTH(window.innerWidth);
        this.SET_WINDOWS_HEIGHT(window.innerHeight);
      } else {
        this.handleResizeWithDebounce();
      }
    },
    handleResizeWithDebounce: debounce(
      function () {
        this.SET_WINDOW_WIDTH(window.innerWidth);
        this.SET_WINDOWS_HEIGHT(window.innerHeight);
      },
      1500,
      { leading: true }
    ),
    handleMobileKeyboardVisible() {
      if (this.windowWidth === window.innerWidth) {
        if (
          window.innerHeight < this.windowHeight &&
          this.questionInputFocused
        ) {
          this.SET_MOBILE_KEYBOARD_VISIBLE(true);
        } else {
          this.SET_MOBILE_KEYBOARD_VISIBLE(false);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/components';

.main {
  background-color: $dark-color;

  &.hide-topbar .main-container {
    padding-top: $header-height;
  }
}

.main-container {
  padding-top: $header-height + $top-bar-height;

  @media (min-width: $screen-width-md) {
    padding-top: 0;
  }
}

.slide-enter-active {
  transition: 0.3s;
}
.slide-enter {
  transform: translate(100%, 0);
}
</style>
