import { getFormDefaults } from '@/checkoutForm/lib/payment-form';
import { OrderIntentType } from '~/checkoutForm/lib/constants';
import { getStore } from '@/lib/analytics/utils';

export const namespaced = true;

const defaultIntent = OrderIntentType.Standard;

export const state = () => ({
  form: getFormDefaults(),
  walletPaymentRequestData: null,
  order: null,
  discount: null,
  intent: defaultIntent
});

export const actions = {
  async applyDiscount({ commit, rootGetters }, code) {
    try {
      const { data } = await this.$axios.get(`/api/coupons/store/${code}`);
      if (data.productIds.length) {
        const products = getStore().getters['checkout/products'];
        const isDiscountProductExist = products.some((prd) =>
          data.productIds.includes(prd._id)
        );
        if (!isDiscountProductExist) {
          // has to disable it to throw acceptable object, not a new Error template string
          // eslint-disable-next-line no-throw-literal
          throw {
            response: {
              status: 404,
              data: {
                code: 404,
                statusCode: 404,
                name: 'Not found',
                message: `Promo code ${code} does not apply to any products in the basket`
              }
            }
          };
        }
      }
      commit('SET_DISCOUNT_DATA', { ...data, code });
    } catch (e) {
      let payload = { error: true };
      if (e.response.data) payload = { ...payload, ...e.response.data };
      commit('SET_DISCOUNT_DATA', payload);
    }
  }
};

export const mutations = {
  SET_DISCOUNT_DATA: (state, data) => {
    state.discount = data;
  }
};

export const getters = {
  discount: ({ discount }) => discount
};
