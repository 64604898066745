import _ from 'lodash';
import { localeToCountryCode } from '~/checkoutForm/lib/constants';

export const getFormDefaults = (data = {}) => ({
  email: '',
  firstName: '',
  surname: '',
  address1: '',
  address2: '',
  city: '',
  country: localeToCountryCode.us,
  postcode: '',
  phone: '',
  shippingMethodId: null,
  shippingPrice: null,
  state: null,
  enableOptIn: null,
  billing: {
    country: data.country || localeToCountryCode.us,
    postcode: '',
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: null
  },
  ...data
});

export const prepareFormForGetter = (form, shippingMethods) => {
  return {
    ...form,
    shippingMethodName: _.get(
      _.find(shippingMethods, ['_id', form.shippingMethodId]),
      'name',
      ''
    )
  };
};

export const prepareFormForUpdate = (form = {}, orderPayment) => ({
  ...form,
  shippingMethodId: orderPayment.shippingMethodId,
  shippingPrice: orderPayment.shippingPrice
});
