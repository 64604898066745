import { useBody } from 'h3';
import verifyReCaptchaToken from '../services/captcha/verifyReCaptchaToken';

export default async (req, res) => {
  res.setHeader('Content-Type', 'application/json');

  const InvalidTokenMessage = JSON.stringify({
    success: false,
    message: 'Invalid token'
  });

  try {
    const { token } = await useBody(req);

    if (!token) {
      res.end(InvalidTokenMessage);
      return;
    }

    const answer = await verifyReCaptchaToken(token);

    const { success = false, score = 0 } = answer;
    const minScore = 0.5;

    if (success && score > minScore) {
      res.end(
        JSON.stringify({
          success,
          score,
          message: 'Token verified'
        })
      );
    } else {
      res.end(InvalidTokenMessage);
    }
  } catch (e) {
    res.end(InvalidTokenMessage);
  }
};
