import { enableBodyScroll } from 'body-scroll-lock';
import { set } from 'vue-gtag';
import { fetchSections } from '@/services/sections';

export const state = () => ({
  menuVisible: false,
  userAgent: null,
  platform: null,
  menuItems: [
    {
      title: 'Discover',
      path: '/discover'
    },
    {
      title: 'Blog',
      path: '/blog'
    },
    {
      title: 'About us',
      path: '/about-us'
    }
  ],
  payoutCode: {
    code: null,
    source: null
  },
  windowWidth: 0,
  windowHeight: 0,
  questionInputFocused: false,
  mobileKeyboardVisible: false,
  sections: null
});

export const mutations = {
  SET_MENU_VISIBLE(state, value) {
    if (!value) {
      const menuElement = document.getElementById('mobile-menu');
      if (menuElement) enableBodyScroll(menuElement);
    }
    state.menuVisible = value;
  },
  SET_MOBILE_KEYBOARD_VISIBLE(state, value) {
    state.mobileKeyboardVisible = value;
  },
  SET_QUESTION_INPUT_FOCUSED(state, value) {
    state.questionInputFocused = value;
  },
  SET_PAYOUT_CODE(state, payload) {
    localStorage.setItem('PAYOUT_CODE', payload.code);
    localStorage.setItem('PAYOUT_SOURCE', payload.source);

    set({
      campaign: {
        name: payload.code,
        source: payload.source
      }
    });
    state.payoutCode = payload;
  },
  SET_SOURCE_CODE(state, payload) {
    set({
      campaign: {
        source: payload
      }
    });
    state.payoutCode.source = payload;
  },
  REMOVE_PAYOUT_CODE(state) {
    localStorage.removeItem('PAYOUT_CODE');
    state.payoutCode.code = null;
  },
  SET_WINDOW_WIDTH(state, value) {
    state.windowWidth = value;
  },
  SET_WINDOWS_HEIGHT(state, value) {
    state.windowHeight = value;
  },
  SET_USER_AGENT(state, value) {
    state.userAgent = value;
  },
  SET_NAVIGATOR_PLATFORM(state, value) {
    state.platform = value;
  },
  SET_SECTIONS(state, sections) {
    const staticMenuItems = [
      {
        title: 'How it works',
        path: '/'
      },
      {
        title: 'Our creators',
        path: '/creators'
      }
    ];
    sections.forEach((section) => {
      state.menuItems.unshift({
        title: section.name,
        path: section.url,
        categories: section.categories,
        dynamic: true
      });
    });
    state.menuItems.unshift(...staticMenuItems);
    state.sections = sections;
  }
};

export const getters = {
  isMobile: (state) => state.windowWidth <= 768,
  categories: (state) => {
    const categories = [];
    state.sections.forEach((section) => {
      categories.push(...section.categories);
    });
    return categories;
  },
  isInstagramBrowser: (state) =>
    state.userAgent
      ? state.userAgent.toLowerCase().includes('instagram')
      : false,
  isAndroidBrowser: (state) =>
    state.userAgent ? state.userAgent.toLowerCase().includes('android') : false,
  isMacOS: (state) => {
    const expression = /(Mac|iPhone|iPod|iPad)/i;
    return expression.test(state.platform);
  }
};

export const actions = {
  async getMenuSections({ commit }) {
    try {
      const result = await fetchSections(this.$axios, {
        query: {
          $limit: 30,
          $populate: {
            path: 'categories',
            $populate: { path: 'nestedCategories' }
          },
          $sort: { createdAt: -1 }
        }
      });
      commit('SET_SECTIONS', result.data);
    } catch (e) {
      console.log('Sections error: ', e);
    }
  }
};
