<template>
  <div>
    <img
      v-if="variant === 'white'"
      src="@/assets/icons/peak.svg"
      alt="switch-icon"
      class="switch-icon"
      :class="{ 'switch-icon-active': isActive }"
      :style="style"
    />
    <img
      v-if="variant === 'dark'"
      src="@/assets/icons/peak-dark.svg"
      alt="switch-icon"
      class="switch-icon"
      :class="{ 'switch-icon-active': isActive }"
      :style="style"
    />
  </div>
</template>

<script>
export default {
  name: 'SwitchIcon',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    },
    variant: {
      type: String,
      default: 'white',
      enum: ['dark', 'white']
    }
  },
  computed: {
    style() {
      if (!this.size) return null;
      return { width: `${this.size}px` };
    }
  }
};
</script>

<style lang="scss" scoped>
.switch-icon {
  height: auto;
  transition: 0.3s;
  transform: rotate(180deg);

  &-active {
    transform: rotate(0deg);
  }
}
</style>
