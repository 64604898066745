<template>
  <div>
    <div class="topbar container justify-content-end">
      <!--
      <a class="header__link mr-md-4 mr-2" href="tel:+4401708836863">
        <img
          class="cursor-pointer mr-2"
          src="@/assets/icons/phone.svg"
          alt="phone"
        />
        <span>+44 (0) 1708 836 863</span>
      </a>
      -->
      <a class="header__link mr-md-4 mr-3" href="mailto:hello@found.us">
        <img
          class="cursor-pointer mr-2"
          src="@/assets/icons/mail.svg"
          alt="mail"
        />
        <span>hello@found.us</span>
      </a>
      <client-only>
        <RegionDropdown />
      </client-only>
    </div>
    <header class="header container" :class="[headerClass]">
      <NuxtLink
        id="Found_menu"
        class="header__logo"
        exact-active-class=""
        :to="localePath('/')"
      >
        <img
          v-if="primaryPageNames.includes($route.name)"
          class="cursor-pointer"
          src="@/assets/logo-dark.svg"
          alt="logo"
        />
        <img v-else class="cursor-pointer" src="@/assets/logo.svg" alt="logo" />
      </NuxtLink>
      <div class="header__desktop-items">
        <NuxtLink
          v-for="item in menuItems"
          :key="item.title"
          :to="localePath(item.path)"
          :event="item.dynamic ? '' : 'click'"
          :class="{ 'header__dynamic-item': item.dynamic }"
        >
          {{ item.title }}
          <span v-if="item.dynamic" class="header__desktop-item-overlay"></span>
          <svg
            v-if="item.dynamic"
            class="header__dynamic-item-arrow"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.0002 9.1697C16.8128 8.98345 16.5594 8.87891 16.2952 8.87891C16.031 8.87891 15.7776 8.98345 15.5902 9.1697L12.0002 12.7097L8.46019 9.1697C8.27283 8.98345 8.01938 8.87891 7.75519 8.87891C7.49101 8.87891 7.23756 8.98345 7.05019 9.1697C6.95646 9.26266 6.88207 9.37326 6.8313 9.49512C6.78053 9.61698 6.75439 9.74769 6.75439 9.8797C6.75439 10.0117 6.78053 10.1424 6.8313 10.2643C6.88207 10.3861 6.95646 10.4967 7.05019 10.5897L11.2902 14.8297C11.3832 14.9234 11.4938 14.9978 11.6156 15.0486C11.7375 15.0994 11.8682 15.1255 12.0002 15.1255C12.1322 15.1255 12.2629 15.0994 12.3848 15.0486C12.5066 14.9978 12.6172 14.9234 12.7102 14.8297L17.0002 10.5897C17.0939 10.4967 17.1683 10.3861 17.2191 10.2643C17.2699 10.1424 17.296 10.0117 17.296 9.8797C17.296 9.74769 17.2699 9.61698 17.2191 9.49512C17.1683 9.37326 17.0939 9.26266 17.0002 9.1697Z"
              fill="#ffffff"
            />
          </svg>

          <span class="header__dropdown">
            <span
              v-for="category in item.categories"
              :key="category.id"
              :class="{
                'header__dynamic-item-active': category.url === $route.path
              }"
              @click="handleCategoryClick(category)"
            >
              {{ category.name }}
            </span>
          </span>
        </NuxtLink>
      </div>
      <div class="ml-auto d-flex">
        <NuxtLink
          id="basket_menu"
          exact-active-class=""
          class="header__basket"
          :to="localePath('/basket')"
        >
          <span>{{ basketItemsValue }}</span>
          <svg
            class="header__icons"
            width="25"
            height="25"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1V9C1 10.1046 1.89543 11 3 11H14C15.1046 11 16 10.1046 16 9V1"
              stroke="white"
              stroke-width="1.12"
              stroke-linecap="round"
            />
            <circle
              cx="3"
              cy="15"
              r="1.44"
              stroke="white"
              stroke-width="1.12"
            />
            <circle
              cx="14"
              cy="15"
              r="1.44"
              stroke="white"
              stroke-width="1.12"
            />
          </svg>
        </NuxtLink>
        <NuxtLink
          id="search_menu"
          exact-active-class=""
          class="header__search"
          :to="localePath('/search')"
        >
          <svg
            class="ml-3 header__icons"
            width="25"
            height="25"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.7546 23.6694C18.7384 23.6694 23.5893 18.8185 23.5893 12.8347C23.5893 6.85086 18.7384 2 12.7546 2C6.77078 2 1.91992 6.85086 1.91992 12.8347C1.91992 18.8185 6.77078 23.6694 12.7546 23.6694Z"
              stroke="white"
              stroke-width="2"
              stroke-miterlimit="10"
            />
            <path
              d="M20.3916 20.5879L28.0031 28.1994"
              stroke="white"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
          </svg>
        </NuxtLink>
        <svg
          class="header__mobile-menu cursor-pointer header__icons ml-3"
          width="24"
          height="19"
          viewBox="0 0 24 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="handleMenuButtonClick"
        >
          <rect width="24" height="2.4" rx="1.5" fill="white" />
          <rect y="8" width="24" height="2.4" rx="1.5" fill="white" />
          <rect y="16" width="24" height="2.4" rx="1.5" fill="white" />
        </svg>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import RegionDropdown from '@/components/RegionDropdown';

export default {
  name: 'HeaderComponent',

  components: {
    RegionDropdown
  },

  data() {
    return {
      primaryPageNames: ['index', 'creators', 'products']
    };
  },

  computed: {
    ...mapState('main', ['menuItems']),
    ...mapGetters({
      countItems: 'checkout/countItems'
    }),

    basketItemsValue() {
      if (this.countItems === 0) {
        return '';
      }
      if (this.countItems > 100) {
        return '*';
      }
      return this.countItems;
    },

    headerClass() {
      const primaryPageNames = this.primaryPageNames;
      const beCreatorPageName = 'be-found';

      const transparentClasses = 'header__transparent';
      const primaryClasses = 'header__discovery';
      const secondaryClasses = 'header__secondary';

      if (this.$route.name === beCreatorPageName) {
        return transparentClasses;
      }
      if (primaryPageNames.includes(this.$route.name)) {
        return primaryClasses;
      }
      return secondaryClasses;
    }
  },
  methods: {
    ...mapMutations({
      SET_MENU_VISIBLE: 'main/SET_MENU_VISIBLE'
    }),
    handleMenuButtonClick() {
      this.SET_MENU_VISIBLE(true);
    },
    handleNavigateClick(path) {
      this.$router.push(path);
    },
    handleCategoryClick(category) {
      this.$router.push(category.url);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/components';

.main.hide-topbar {
  .topbar {
    display: none;
  }
  .header {
    top: 0;
  }
}

.topbar,
.header {
  display: flex;
  position: fixed;
  top: 0;
  background-color: $dark-color;

  @media (max-width: $screen-width-md) {
    max-width: 100%;
  }

  @media (min-width: $screen-width-md) {
    position: relative;
  }
}

.topbar {
  height: $top-bar-height;
  z-index: 20;

  & > .container {
    display: flex;
    justify-content: flex-end;
  }
}

.header {
  align-items: center;
  height: $header-height;
  top: $top-bar-height;
  z-index: 10;

  &__logo {
    padding-top: 5px;
  }

  &__icons {
    circle,
    path {
      stroke: $white-color;
    }

    rect {
      fill: $white-color;
    }
  }

  .header__desktop-item-overlay {
    width: 100%;
    height: 50px;
    position: absolute;
    z-index: 3;
  }

  &__dropdown {
    display: none;
    min-width: 150px;
    background-color: $white-color;
    box-shadow: 4px 4px 8px rgba(#000000, 45%);
    border-radius: 8px;
    position: absolute;
    top: 39px;
    flex-direction: column;
    right: 0;

    span {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 25px;
      line-height: 25px;
      width: 100%;
      text-align: right;
      padding-top: 7px;
      padding-bottom: 7px;

      &:hover {
        background-color: $primary-color;
      }

      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  &__basket {
    position: relative;

    span {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 4px;
      text-align: center;
      color: $primary-color;
      font-size: $font-size-semi-large;
    }
  }

  &__desktop-items {
    display: none;
    height: 30px;
    width: 100%;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      color: $white-color;
      font-weight: $font-weight-bold;

      &:hover {
        text-decoration: none;
      }
      &:first-child {
        margin-left: auto;
      }
      &:last-child {
        margin-right: 80px;
      }
    }

    @media (min-width: $screen-width-md) {
      display: flex;
    }
  }

  .header__desktop-item-overlay {
    &:hover {
      .header__dropdown {
        display: flex;
      }

      .header__dynamic-item-arrow {
        transform: rotateZ(-180deg);
      }
    }
  }

  &__link {
    text-decoration: none;

    font-family: 'Poppins-SemiBold', sans-serif;
    font-size: 14px;
    color: $white-color;
    line-height: 38px;

    @media (max-width: $screen-width-md) {
      span {
        display: none;
      }
    }
  }

  &__discovery {
    .header__desktop-items {
      a {
        font-weight: bold;
        color: $white-color;
        transition: background-color 0.2s ease-out;
        padding: 0 5px;
        border-radius: 8px;

        &:hover {
          color: $dark-color;
          background-color: $primary-color;
          border-bottom: none;
        }
      }

      .header__dynamic-item {
        position: relative;

        &-arrow {
          transition: all 0.4s ease;
        }

        &:hover {
          .header__dropdown {
            display: flex;
          }

          .header__dynamic-item-arrow {
            transform: rotateZ(-180deg);
          }
        }
      }
    }
  }

  &__secondary,
  &__transparent {
    .header__desktop-items {
      a {
        font-weight: bold;
        transition: background-color 0.2s ease-out;
        padding: 0 5px;
        border-radius: 8px;

        &:hover {
          background-color: $dark-secondary-color;
          border-bottom: none;
        }
      }
    }

    .header__dynamic-item {
      position: relative;

      &-arrow {
        transition: all 0.4s ease;
      }

      &:hover {
        .header__dropdown {
          display: flex;
        }

        .header__dynamic-item-arrow {
          transform: rotateZ(-180deg);
        }
      }

      span {
        color: $black-color;
      }

      svg {
        path {
          fill: $white-color;
        }
      }
    }
  }

  .header__dynamic-item-active {
    background-color: $secondary-yellow-color;

    &:hover {
      background-color: $secondary-yellow-color;
    }
  }

  &__mobile-menu {
    display: block;
    margin-top: 4px;

    @media (min-width: $screen-width-md) {
      display: none;
    }
  }

  @media (min-width: $screen-width-md) {
    top: 0;
    margin-top: 0;
  }

  @media (max-width: $screen-width-lg) {
    .header__desktop-items {
      a {
        font-size: 8px;
      }
    }
  }
}
</style>

<style lang="scss">
.header__secondary,
.header__transparent {
  .nuxt-link-exact-active {
    background-color: $dark-secondary-color;
    border-bottom: none;
  }
}

.header__discovery {
  .nuxt-link-exact-active {
    color: $dark-color !important;
    background-color: $primary-color;
    border-radius: 8px;
    border-bottom: none;
  }
}

.header__transparent {
  background-color: transparent !important;
  background: linear-gradient(180deg, #000517 16.43%, rgba(0, 5, 23, 0) 100%);
}
</style>
