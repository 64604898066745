<template>
  <div class="container text-center">
    <div class="error-page">
      <h2 class="mb-3">Internal Server Error</h2>
      <p class="mb-4">
        Something has gone wrong. <br />
        Please reload the page or
        <NuxtLink class="error-page__primary-link" :to="localePath('/contact')">
          contact us
        </NuxtLink>
        if the problem persists.
      </p>
      <Button @click="handleReloadClick">Reload</Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Elements/Button';

export default {
  name: 'ErrorPage500',

  components: {
    Button
  },

  methods: {
    handleReloadClick() {
      location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
$header-height: 55px;
$footer-height: 220px;

.error-page {
  height: calc(100vh - ($header-height + $footer-height));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('assets/500.svg');
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;

  &__primary-link {
    color: $primary-color;
  }
}
</style>
