import _ from 'lodash';
import Promise from 'bluebird';
import getFormatPrice from '@/misc/getFormatPrice';
import { getStore } from '@/lib/analytics/utils';
import { fetchOneProduct } from '@/services/products';
import { axiosInstance } from '@/lib/axiosInstance';

const productCache = {};

const getFullProduct = (product) => {
  const hasCreatorHandle = !!product.creator?.profile?.handle;
  const hasCategories = product.categoryIds?.length
    ? !!product.categories?.[0]?.name
    : true;

  if (hasCreatorHandle && hasCategories) return product;
  productCache[product._id] = fetchOneProduct(axiosInstance, product._id, {
    query: { $populate: [{ path: 'creator' }, { path: 'categories' }] }
  });

  return productCache[product._id];
};

const createProductData = async (product, region) => {
  const formats = product.formats;
  const formatId = product.productFormatId;
  const selectedProductFormat = formats.find(({ _id }) => _id === formatId);
  const displayUnitPrice = getFormatPrice(selectedProductFormat, region);
  const displayUnitPriceAmount = +(displayUnitPrice.amount / 100).toFixed(2);

  const fullProduct = await getFullProduct(product);

  return {
    productName:
      fullProduct.name +
      (selectedProductFormat?.name ? ` (${selectedProductFormat?.name})` : ''),
    productId: fullProduct._id,
    variant: selectedProductFormat?.name,
    categories: fullProduct.categories?.map((c) => c.name),
    imageURL: fullProduct.previewImages?.[0]?.url,
    creatorHandle: fullProduct.creator?.profile?.handle,
    creatorId: fullProduct.creator?._id,
    storeRegion: region,
    ...(displayUnitPriceAmount && { displayPrice: displayUnitPriceAmount })
  };
};

export const trackProductViewedEvent = async ({ product }) => {
  if (!(await waitForKlaviyoInit())) return;

  const region = getStore().getters['config/region'];
  const item = await createProductData(product, region);

  window.klaviyo.push(['track', 'Viewed Product', item]);
};

export const trackAddToCartEvent = async ({ cartItem, cart }) => {
  if (!(await waitForKlaviyoInit())) return;

  const region = getStore().getters['config/region'];
  const item = await createProductData(cartItem, region);
  const klaviyoCartItems = await Promise.map(cart, async (item) => {
    const itemData = await createProductData(item, region);
    const qty = item.quantity ?? 1;

    return {
      ...itemData,
      quantity: qty,
      ...(itemData.displayUnitPrice && {
        displayUnitPrice: itemData.displayUnitPrice,
        displayPrice: itemData.displayUnitPrice * qty
      })
    };
  });

  const totalDisplayPrice = _.sumBy(klaviyoCartItems, 'displayPrice');

  window.klaviyo.push([
    'track',
    'Added to Cart',
    {
      $value: totalDisplayPrice,
      totalDisplayPrice,
      addedItemProductName: item.productName,
      addedItemProductID: item.productId,
      addedItemVariant: item.variant,
      addedItemImageURL: item.imageURL,
      addedItemPrice: item.displayPrice,
      addedItemQuantity: cartItem.quantity ?? 1,
      addedItemCategories: item.categories,
      storeRegion: region,
      itemProductNames: klaviyoCartItems.map((i) => i.productName),
      itemProductIds: klaviyoCartItems.map((i) => i.productId),
      itemProductVariants: klaviyoCartItems.map((i) => i.variant),
      itemCreatorHandles: klaviyoCartItems.map((i) => i.creatorHandle),
      itemCategories: Array.from(
        new Set(klaviyoCartItems.flatMap((i) => i.categories))
      ),
      items: klaviyoCartItems
    }
  ]);
};

export const trackRemoveFromCartEvent = async ({ cart, cartItem }) => {
  if (!(await waitForKlaviyoInit())) return;

  const region = getStore().getters['config/region'];
  const item = await createProductData(cartItem, region);
  const klaviyoCartItems = await Promise.map(cart, async (item) => {
    const itemData = await createProductData(item, region);
    const qty = item.quantity ?? 1;

    return {
      ...itemData,
      quantity: qty,
      ...(itemData.displayUnitPrice && {
        displayUnitPrice: itemData.displayUnitPrice,
        displayPrice: itemData.displayUnitPrice * qty
      })
    };
  });

  const totalDisplayPrice = _.sumBy(klaviyoCartItems, 'displayPrice');

  window.klaviyo.push([
    'track',
    'Removed from Cart',
    {
      $value: totalDisplayPrice,
      totalDisplayPrice,
      removedItemProductName: item.productName,
      removedItemProductID: item.productId,
      removedItemVariant: item.variant,
      removedItemImageURL: item.imageURL,
      removedItemPrice: item.displayPrice,
      removedItemQuantity: cartItem.quantity ?? 1,
      removedItemCategories: item.categories,
      storeRegion: region,
      itemProductNames: klaviyoCartItems.map((i) => i.productName),
      itemProductIds: klaviyoCartItems.map((i) => i.productId),
      itemProductVariants: klaviyoCartItems.map((i) => i.variant),
      itemCreatorHandles: klaviyoCartItems.map((i) => i.creatorHandle),
      itemCategories: Array.from(
        new Set(klaviyoCartItems.flatMap((i) => i.categories))
      ),
      items: klaviyoCartItems
    }
  ]);
};

export const trackCheckoutEvent = async ({
  cart,
  redirectUrl,
  checkoutSessionId
}) => {
  if (!(await waitForKlaviyoInit())) return;

  const region = getStore().getters['config/region'];
  const klaviyoCartItems = await Promise.map(cart, async (item) => {
    const itemData = await createProductData(item, region);
    const qty = item.quantity ?? 1;

    return {
      ...itemData,
      quantity: qty,
      ...(itemData.displayUnitPrice && {
        displayUnitPrice: itemData.displayUnitPrice,
        displayPrice: itemData.displayUnitPrice * qty
      })
    };
  });
  const totalDisplayPrice = _.sumBy(klaviyoCartItems, 'displayPrice');
  const unixTimestamp = Math.floor(new Date('2012.08.10').getTime() / 1000);

  window.klaviyo.push([
    'track',
    'Started Checkout',
    {
      $value: totalDisplayPrice,
      totalDisplayPrice,
      $event_id: _.compact([checkoutSessionId, unixTimestamp]).join('_'),
      checkoutURL: redirectUrl,
      storeRegion: region,
      itemProductNames: klaviyoCartItems.map((i) => i.productName),
      itemProductIds: klaviyoCartItems.map((i) => i.productId),
      itemProductVariants: klaviyoCartItems.map((i) => i.variant),
      itemCreatorHandles: klaviyoCartItems.map((i) => i.creatorHandle),
      itemCategories: Array.from(
        new Set(klaviyoCartItems.flatMap((i) => i.categories))
      ),
      items: klaviyoCartItems
    }
  ]);
};

export const identify = async ({ order }) => {
  if (!(await waitForKlaviyoInit())) return;

  const shippingDetails = order.shipping;
  if (!shippingDetails?.email && !shippingDetails.phone) return;

  const nameParts = parseName(shippingDetails.name);

  window.klaviyo.identify({
    $email: shippingDetails.email,
    $first_name: nameParts.name,
    $last_name: nameParts.lastName,
    $phone_number: shippingDetails.phone
  });
};

/**
 * Parses a full name and extracts the first name, last name, and second last name (if available).
 *
 * @param {string} input - The full name to be parsed.
 * @returns {Record<'name' | 'lastName' | 'firstLastName' | 'secondLastName', string>} name - An object containing the parsed name components.
 */
function parseName(input) {
  // Initialize variables
  const fullName = input || '';
  const result = {};

  // Check if the full name is not empty
  if (fullName.length > 0) {
    // Use regex to extract name tokens
    const nameTokens =
      fullName.match(
        /[A-ZÁ-ÚÑÜ][a-zá-úñü]+|([aeodlsz]+\s+)+[A-ZÁ-ÚÑÜ][a-zá-úñü]+/g
      ) || [];

    // Check the number of name tokens
    if (nameTokens.length > 3) {
      result.name = nameTokens.slice(0, 2).join(' ');
    } else {
      result.name = nameTokens.slice(0, 1).join(' ');
    }

    // Extract last names
    if (nameTokens.length > 2) {
      result.firstLastName = nameTokens.slice(-2, -1).join(' ');
      result.secondLastName = nameTokens.slice(-1).join(' ');
      result.lastName = `${result.firstLastName} ${result.secondLastName}`;
    } else {
      result.lastName = result.firstLastName = nameTokens.slice(-1).join(' ');
      result.secondLastName = '';
    }
  }

  // Return the parsed name components
  return result;
}

let isKlavyioAdBlocked = false;

/**
 * Waits for Klaviyo to be loaded via the <script> tag and resolves the promise
 * when successful. If not loaded within 100 seconds, it throws an error
 * and marks Klaviyo as ad-blocked for future attempts.
 *
 * @returns {Promise<boolean>} Resolves with `true` if Klaviyo is successfully loaded,
 * or `false` if it's detected as ad-blocked.
 */
function waitForKlaviyoInit() {
  return new Promise((resolve, reject) => {
    let tries = 0;
    const maxTries = 150;
    const intervalDuration = 200;

    const check = () => {
      if (isKlavyioAdBlocked) {
        resolve(false);
        return;
      }

      if (++tries > maxTries) {
        isKlavyioAdBlocked = true;
        reject(new Error('Unable to load Klaviyo'));
        return;
      }

      if (window.klaviyo) {
        resolve(true);
        return;
      }

      setTimeout(check, intervalDuration);
    };

    check();
  });
}
