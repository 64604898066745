import {
  fetchShippingMethod,
  fetchActiveRegionShippingMehods
} from '@/services/shippingMethods';
import getShippingRate from '~/misc/getShippingRate';

export const state = () => ({
  shippingMethod: {}
});

export const actions = {
  async fetchShippingMethodById({ commit, rootState }, id) {
    const {
      config: { region }
    } = rootState.config;

    const shippingMethod = await fetchShippingMethod(this.$axios, id);
    const shippingRate = getShippingRate(shippingMethod?.shippingRates, region);

    commit('SET_SHIPPING_METHOD', { ...shippingMethod, shippingRate });
  },

  async fetchActiveRegionShippingMethods({ commit, rootState }) {
    const {
      config: { region }
    } = rootState.config;

    const response = await fetchActiveRegionShippingMehods(this.$axios, {
      query: {
        $where: {
          active: true,
          'shippingRates.region': region
        }
      }
    });

    const { data: shippingMethods } = response;

    const shippingMethod = shippingMethods?.length ? shippingMethods[0] : {};

    const shippingRate = getShippingRate(shippingMethod?.shippingRates, region);

    commit('SET_SHIPPING_METHOD', { ...shippingMethod, shippingRate });
  }
};

export const mutations = {
  SET_SHIPPING_METHOD: (state, data) => {
    state.shippingMethod = data;
  }
};

export const getters = {
  shippingMethod: (state) => state.shippingMethod,
  shippingRate: (state) => state.shippingMethod.shippingRate
};
