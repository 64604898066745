// eslint-disable-next-line import/no-mutable-exports
let storeInstance;

/**
 * Provides a way to access the Nuxt store within JavaScript files that are
 * outside the Nuxt.js context.
 *
 * This implementation is based on a solution from Stack Overflow.
 *
 * @see {@link https://stackoverflow.com/a/68923127/962135} for the original discussion.
 */
export function init(context) {
  storeInstance = context.store;
}

export { storeInstance };
