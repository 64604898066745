export const productAvailability = {
  PreOrder: 'pre-order',
  Available: 'available'
};

export const OrderIntentType = {
  Standard: 'standard',
  Test: 'test',
  Sample: 'sample'
};

export const localeToCountryCode = {
  gb: 'GB',
  us: 'US',
  en_us: 'US'
};
