import Vue from 'vue';

const defaultParams = {
  autoHideDelay: 4500,
  appendToast: false,
  noCloseButton: true,
  toaster: 'b-toaster-bottom-center'
};

function customToast(message, title, variant = 'success') {
  return this.$bvToast.toast(message, {
    title,
    variant,
    ...defaultParams
  });
}

Vue.set(Vue.prototype, '$showToastMessage', customToast);
