<template>
  <footer class="footer d-flex" :class="[footerClass]">
    <div class="container">
      <div class="container__icons d-flex justify-content-center">
        <a
          v-for="(link, key) in socialLinks"
          :key="key"
          :href="link"
          target="_blank"
        >
          <img :src="getImageItem(key)" :alt="key" />
        </a>
      </div>

      <div class="footer__links d-flex justify-content-center mb-3">
        <!-- add mr-4 to the email block after reverting
        <a class="footer__link mr-4" href="tel:+4401708836863">
          <img
            class="cursor-pointer mr-2"
            src="@/assets/icons/phone.svg"
            alt="phone"
          />
          <span>+44 (0) 1708 836 863</span>
        </a>
        -->
        <a class="footer__link" href="mailto:hello@found.us">
          <img
            class="cursor-pointer mr-2"
            src="@/assets/icons/mail.svg"
            alt="mail"
          />
          <span>hello@found.us</span>
        </a>
      </div>

      <div class="footer__payment-icons">
        <payment-icons variant="footer" />
      </div>

      <div
        class="d-flex justify-content-center flex-column align-items-center mb-3"
      >
        <p class="mb-1">Powered by</p>
        <NuxtLink exact-active-class="" :to="localePath('/')">
          <img src="@/assets/logo.svg" alt="logo" width="160" height="40" />
        </NuxtLink>
      </div>
    </div>

    <div class="footer__terms-and-conditions mt-auto">
      <NuxtLink :to="localePath('/privacy')">Privacy</NuxtLink> |
      <NuxtLink :to="localePath('/terms')">Terms</NuxtLink> |
      <NuxtLink :to="localePath('/cookies')">Cookies</NuxtLink> |
      <NuxtLink :to="localePath('/contact')">Contact us</NuxtLink> |
      <NuxtLink :to="localePath('/be-found')">Become Found</NuxtLink>
    </div>
  </footer>
</template>

<script>
import socialLinks from '@/misc/socialLinks';
import PaymentIcons from '@/components/PaymentIcons/PaymentIcons.vue';

export default {
  name: 'FooterComponent',
  components: { PaymentIcons },

  data() {
    return {
      socialLinks,
      pageNames: [
        'creators-id',
        'products-id',
        'recipe-id',
        'slug-id',
        'basket'
      ]
    };
  },

  computed: {
    footerClass() {
      if (this.pageNames.includes(this.$route.name)) {
        return this.$route.name === 'basket'
          ? 'footer__margin_basket'
          : 'footer__margin';
      }
      return '';
    }
  },

  methods: {
    getImageItem(key) {
      return require(`@/assets/icons/social/${key}.svg`);
    }
  }
};
</script>

<style lang="scss" scoped>
$height: 150px;

.footer {
  min-height: $height;
  flex-direction: column;
  padding: 25px 10px;
  font-size: $font-size-smaller;
  color: $light-grey-color;

  .container {
    &__icons {
      margin-bottom: 25px;

      a {
        margin: 0 15px;
      }
    }
  }

  &__link {
    text-decoration: none;
    font-family: 'Poppins-SemiBold', sans-serif;
    font-size: 14px;
    color: $white-color;
    line-height: 38px;
  }

  &__terms-and-conditions {
    text-align: center;

    a {
      font-size: $font-size-smaller;
      color: $light-grey-color;
    }
  }

  &__links {
    flex-wrap: wrap;
  }

  &__payment-icons {
    max-width: 336px;
    margin: 40px auto;
  }

  @media (max-width: $screen-width-md) {
    &__margin {
      margin-bottom: 75px;
    }

    &__terms-and-conditions {
      a {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  @media (max-width: $screen-width-lg) {
    &__margin {
      &_basket {
        margin-bottom: 280px;
      }
    }
  }
}
</style>
