import Cookies from 'js-cookie';

export default function reloadPageWithoutCache() {
  // The following short-living cookie header will notify the Vercel
  // that the content shouldn't be taken from the cache.
  // See https://vercel.com/docs/concepts/functions/serverless-functions/edge-caching#what-is-cached
  const in10Seconds = new Date(new Date().getTime() + 10 * 1000);
  Cookies.set('_vercel_no_cache', '1', { expires: in10Seconds });

  location.reload();
}
