import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import { createCheckoutSession } from '@/services/checkout';
import {
  trackAddToCartEvent,
  trackCheckoutEvent,
  trackRemoveFromCartEvent
} from '@/lib/analytics';

export const state = () => ({
  products: [],
  sessionId: ''
});

export const actions = {
  async createSession(
    { commit, dispatch, state, getters, rootState, rootGetters },
    couponCode
  ) {
    const {
      config: { currency, storeRegion }
    } = rootState.config;
    const payoutCode = localStorage.getItem('PAYOUT_CODE');
    const payload = {
      payoutCode,
      items: map(state.products, (i) => ({
        productId: i._id,
        quantity: i.quantity,
        previewUrl: i.previewImages?.[0]?.url,
        customizationQuery: i.customizationQuery,
        productFormatId: i.productFormatId
      })),
      storeRegion,
      shippingMethodId: rootGetters['config/shippingMethodId'],
      isoCurrency: currency,
      customerUserAgent: window.navigator.userAgent,
      couponCode,
      // This CHECKOUT_SESSION_ID is merged by Stripe, we don't need to handle it.
      successUrl: `${window.location.origin}/basket/success/?session_id={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${window.location.origin}/basket/`
    };

    const { redirectUrl, checkoutSessionId } = await createCheckoutSession(
      this.$axios,
      payload
    );
    trackCheckoutEvent({
      cart: state.products,
      redirectUrl,
      checkoutSessionId
    });

    commit('SET_CHECKOUT_REDIRECT_URL', redirectUrl);
  }
};

export const mutations = {
  ADD_ITEM(state, { product, count, discounted, productFormatId }) {
    const { _id: id } = product;
    const newProduct = { ...product };
    const cartItem = {
      id: id + new Date().valueOf(),
      quantity: count || 1,
      productFormatId,
      discounted: false, // TODO: set discounted property
      ...newProduct
    };
    state.products?.push(cartItem);

    /**
     * The following function call below is not appropriately placed.
     * Side effects should be handled in Vuex actions, not mutations.
     * However, due to the widespread direct referencing and invocation of the
     * checkout/ADD_ITEM mutation across various components (a non-recommended practice),
     * this mutation remains the sole location for these shared operations.
     */
    trackAddToCartEvent({ cartItem, cart: state.products });
  },
  REMOVE_ITEM(state, index) {
    const deletedItems = state.products?.splice(index, 1);
    trackRemoveFromCartEvent({
      cart: state.products,
      cartItem: deletedItems[0]
    });
  },
  REMOVE_ITEMS(state, productIds = []) {
    state.products = state.products?.filter(
      (product) => !productIds.includes(product._id)
    );
  },
  CHANGE_ITEM_QUANTITY: (state, { index, value }) => {
    const item = state.products?.[index];
    if (item) {
      item.quantity = value ? Math.min(Math.max(1, +value), 99) : '';
    }
  },
  CHANGE_ITEM_FORMAT: (state, { index, value }) => {
    const item = state.products?.[index];
    if (item) {
      item.productFormatId = value;
      item.quantity = 1;
    }
  },
  CLEAR_ITEMS: (state) => {
    state.products = new Array(0);
  },
  SET_CHECKOUT_REDIRECT_URL: (state, redirectUrl) => {
    state.redirectUrl = redirectUrl;
  }
};

export const getters = {
  redirectUrl: (state) => state.redirectUrl,
  products: (state) => state.products,
  countItems: (state) => sumBy(state.products, 'quantity'),
  isDiscountApplied: (state) =>
    state.products.filter((product) => product.discounted === true).length > 1
};
