export default function formatMoney(amount, currency = 'USD', options = {}) {
  if (!isNaN(amount) && currency) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      ...options
    });
    return formatter.format(amount / 100);
  }
}

export function getCurrencySymbol(currency) {
  const ZERO_PRICE = 0;

  const parts = ZERO_PRICE.toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
    .replace(/\d/g, '')
    .trim()
    .split('');

  // Handle cases where the currency symbol might be prefixed with the currency code
  const filteredParts = parts.filter((part) => !/[a-zA-Z]/.test(part));

  return filteredParts.join('').trim();
}
