import UsaIcon from '@/assets/icons/flags/usa.svg';
import CanadaIcon from '@/assets/icons/flags/canada.svg';
import BritainIcon from '@/assets/icons/flags/britain.svg';
import GlobalIcon from '@/assets/icons/flags/global.svg';
import LATAMIcon from '@/assets/icons/flags/latam.svg';
import EUIcon from '@/assets/icons/flags/eu.svg';

export const countryIcons = {
  US: UsaIcon,
  GB: BritainIcon,
  CA: CanadaIcon,
  GO: GlobalIcon,
  LATAM: LATAMIcon,
  EU: EUIcon
};
