import { LatinAmericanCountry, EUCountry } from '@/misc/constants';

export default function getRegionByCountry(countryCode) {
  const countriesAsRegion = {
    US: 'US',
    GB: 'GB',
    CA: 'CA'
  };

  if (countriesAsRegion[countryCode]) {
    return countriesAsRegion[countryCode];
  } else if (EUCountry[countryCode]) {
    return 'EU';
  } else if (LatinAmericanCountry[countryCode]) {
    return 'LATAM';
  }

  return 'GO';
}
