<template>
  <b-modal
    :visible="value"
    dialog-class="ConfirmModal"
    content-class="ConfirmModal__content"
    body-class="ConfirmModal__body"
    centered
    hide-header
    hide-footer
    @change="$emit('input', $event)"
  >
    <CloseButton class="CloseBtn" @click="onClose" />
    <h3>Items unavailable</h3>
    <p>Sorry, some of your items are not available in</p>
    <p>
      <span class="text-semibold">{{ country }}</span
      >.
    </p>
    <p>If you continue these items will be removed from your basket.</p>
    <div class="ModalActions mt-4">
      <Button class="mr-4" @click="$emit('ok')">I'm ok with this</Button>
      <Button variant="outline-inverse" @click="onClose">Cancel</Button>
    </div>
  </b-modal>
</template>

<script>
import Button from '@/components/Elements/Button';
import CloseButton from '@/components/Elements/CloseButton.vue';

export default {
  components: {
    Button,
    CloseButton
  },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    region: {
      type: Object,
      default: null
    },
    products: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    country() {
      return this.region?.country;
    }
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    }
  }
};
</script>

<style lang="scss">
.ConfirmModal {
  max-width: 560px;

  &__content {
    color: #000517;
    text-align: center;
    border: none;
    border-radius: 16px;

    h3 {
      font-family: 'PPRader-Bold', sans-serif;
      font-size: 32px;
      line-height: 46px;
      text-transform: uppercase;
    }

    p {
      line-height: 32px;
      margin-bottom: 0;
    }

    .text-semibold {
      font-family: 'Poppins-SemiBold', sans-serif;
    }
  }

  &__body {
    padding: 50px 35px;

    @media (max-width: $screen-width-md) {
      padding: 45px 15px;
    }

    .CloseBtn {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .ModalActions {
      display: flex;
      justify-content: center;

      a.btn {
        padding: 10px 25px;
      }
    }
  }
}
</style>
